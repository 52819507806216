<template>
  <v-app>
    <router-view :key="$route.path" />
  </v-app>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="scss">
#app {
  background-color: #f5f6f7;
  color: #444 !important;
}
</style>
