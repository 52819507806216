export function setWithExpiry(key, value, minutes) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + minutes * 60 * 1000
  };

  sessionStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemStr = sessionStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (!item.value || !item.expiry || now.getTime() > item.expiry) {
    sessionStorage.removeItem(key);

    return null;
  }

  return item.value;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
