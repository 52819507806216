import Vue from "vue";
import App from "./App.vue";
import { routes } from "./router/index.js";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import VueRouter from "vue-router";
import VueMask from "v-mask";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueMask);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

Vue.use(VueMeta);

Vue.mixin({
  computed: {
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    }
  },
  methods: {
    linkWhatsApp(mensagem) {
      const telefone = "5531992264883";

      return this.isDesktop
        ? `https://web.whatsapp.com/send?l=pt_BR&phone=${telefone}&text=${mensagem}`
        : `https://api.whatsapp.com/send?phone=${telefone}&text=${mensagem}`;
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
