// import produtoRepository from "../repositories/ProdutoRepository";
// import categoriaRepository from "../repositories/CategoriaRepository";
import { setCookie } from "../utils/storage";

export const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/Index.vue"),
    children: [
      {
        path: "",
        name: "Home",
        props: { idCategoria: "sex-shop_produtos-eroticos-mais-vendidos" },
        component: () => import("@/views/Categoria.vue")
      },
      {
        path: "produto/:idProduto",
        name: "produto",
        props: true,
        component: () => import("@/views/Produto.vue")
      },
      {
        path: "r/produto/:idProduto",
        redirect: to => {
          setCookie("afiliacao", "1", 365);

          return { path: "produto/" + to.params.idProduto };
        }
      },
      {
        path: "categoria/:idCategoria",
        name: "categoria",
        props: true,
        component: () => import("@/views/Categoria.vue")
      },
      {
        path: "busca/:termoBusca",
        name: "busca",
        props: true,
        component: () => import("@/views/Categoria.vue")
      }
    ]
  },
  // {
  //   path: "/",
  //   name: "Home",
  //   props: { idCategoria: "sex-shop_produtos-eroticos-mais-vendidos" },
  //   component: () => import("@/views/Categoria.vue")
  // },
  // {
  //   path: "/produto/:idProduto",
  //   name: "produto",
  //   props: true,
  //   component: () => import("@/views/Produto.vue")
  // },
  // {
  //   path: "/categoria/:idCategoria",
  //   name: "categoria",
  //   props: true,
  //   component: () => import("@/views/Categoria.vue")
  // },
  // {
  //   path: "/busca/:termoBusca",
  //   name: "busca",
  //   props: true,
  //   component: () => import("@/views/Categoria.vue")
  // },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/admin/Index.vue"),
    children: [
      {
        path: "preco",
        name: "admin_preco",
        component: () => import("@/views/admin/Preco.vue")
      }
    ]
  },
  {
    path: "/login_admin",
    name: "login_admin",
    component: () => import("@/views/admin/Login.vue")
  }
];
